<template>
  <section>
    <ul
      class="ml-5 md:ml-10 pt-5 md:pt-8 w-auto border-l-2 border-dotted border-gray-e9"
    >
      <li
        v-for="slot in timeSlots"
        :key="slot.id"
        class="mb-5 md:mb-8 w-auto flex flex-row"
      >
        <div
          class="h-3 w-3 -ml-1.5 mr-5 my-auto md:mr-10 bg-gray-e9 rounded-full"
        />
        <button
          type="button"
          class="w-full shadow-card rounded-lg flex flex-row"
          @click="showSlot(slot)"
        >
          <div
            :class="[
              'h-6 w-6 md:h-10 md:w-10 mx-4 md:mx-8 my-auto flex items-center justify-center rounded-full',
              slotFormat(slot, 'bgColor')
            ]"
          >
            <i
              :class="[
                'far text-white text-xs md:text-sm',
                slotFormat(slot, 'icon')
              ]"
            />
          </div>
          <div class="my-6 mr-4 md:mr-8 flex-grow text-left">
            <span class="tg-mobile-body-bold">
              {{ formatHHmm(slot.datetime_start) }} -
              {{ formatHHmm(slot.datetime_end) }}
            </span>
            <br />
            <span :class="slotFormat(slot, 'subHeadingStyle')">
              {{ slotFormat(slot, 'subHeading') }}
            </span>
          </div>
          <div
            class="px-4 md:px-8 h-full flex items-center justify-center border-l-0.5 border-gray-e9"
          >
            <i class="far fa-arrow-right text-gray-cc text-xs md:text-sm" />
          </div>
        </button>
      </li>
    </ul>
    <BaseModal
      v-if="selectedTimeSlot !== null"
      :show-modal="slotModal"
      icon="fa-user-alt"
      @close="closeSlotModal"
    >
      <template #message>
        <div class="mb-6">
          <h4
            class="tg-mobile-header-4 lg:tg-desktop-header-4"
            v-text="$t('visit_reservation_time')"
          />
          <p class="tg-mobile-body-small lg:tg-desktop-body">
            {{ formatDateForLocale(selectedTimeSlot.datetime_start) }}
            <br />
            {{ formatHHmm(selectedTimeSlot.datetime_start) }} -
            {{ formatHHmm(selectedTimeSlot.datetime_end) }}
          </p>
        </div>
        <div v-if="selectedTimeSlot.booking_set.length" class="text-left">
          <details
            v-for="(booking, index) in selectedTimeSlot.booking_set"
            :key="booking.id"
            :open="index === 0 ? true : false"
          >
            <summary class="tg-mobile-header-5 text-brand-primary-dark link">
              {{ $t('booking') }} {{ index + 1 }} ({{
                booking.candidate.first_name +
                  ' ' +
                  booking.candidate.last_name
              }})
            </summary>
            <div
              class="px-5 py-2 tg-mobile-body-small lg:tg-desktop-body flex flex-col space-y-2"
            >
              <span>
                <i class="fal fa-user-alt mr-2.5" />
                {{ booking.candidate.first_name }}
                {{ booking.candidate.last_name }}
              </span>
              <span>
                <i class="fal fa-language mr-1.5" />
                {{ $t('preferred_language') }}:
                {{ langOptions[booking.candidate.language] }}
              </span>
              <a
                :href="'tel:' + booking.candidate.phone_number_mobile"
                class="link underline"
              >
                <i class="fal fa-phone mr-2.5" />
                {{ booking.candidate.phone_number_mobile }}
              </a>
              <a
                :href="'mailto:' + booking.candidate.email"
                class="link underline"
              >
                <i class="fal fa-envelope mr-2.5" />

                {{ booking.candidate.email }}
              </a>
              <a :href="getICS(booking.ics_url)" class="link underline">
                <i class="fal fa-link mr-2.5" />
                {{ $t('add_to_your_own_calendar') }}
              </a>
            </div>
          </details>
        </div>
        <div
          v-else
          class="tg-mobile-body-small lg:tg-desktop-body flex flex-col"
        >
          <span class="text-gray-cc">
            <i class="fal fa-user-alt mr-2.5" />
            {{ $t('not_yet_reserved') }}
          </span>
          <FormulateForm
            #default="{ isLoading, hasErrors }"
            name="propertyVisitSlotDelete"
            @submit="removeTimeSlot(selectedTimeSlot)"
          >
            <button
              type="submit"
              class="text-red-600 hover:text-opacity-60 underline"
              :disabled="hasErrors || isLoading"
            >
              <i
                :class="[
                  'far mr-2.5',
                  isLoading ? 'fa-spinner-third animate-spin' : 'fa-trash'
                ]"
              />
              {{ $t('remove_timeslot') }}
            </button>
            <FormulateErrors />
          </FormulateForm>
        </div>
      </template>
    </BaseModal>
  </section>
</template>

<script>
import { deletePropertyTimeSlot } from '@/services/calendarService';
import { formatHHmm, formatDateForLocale, langOptions } from '@/helpers';

export default {
  name: 'PropertyVisitSeller',
  props: {
    timeSlots: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      langOptions,
      propertyId: this.$route.params.propertyId,
      selectedTimeSlot: null,
      slotModal: false
    };
  },
  methods: {
    formatHHmm,
    formatDateForLocale,
    slotFormat(slot, key) {
      const notReserved = {
          bgColor: 'bg-brand-third',
          icon: 'fa-clock',
          subHeadingStyle: 'tg-mobile-body-small text-gray-cc',
          subHeading: this.$t('not_yet_reserved')
        },
        reserved = {
          bgColor: 'bg-brand-primary',
          icon: 'fa-lock',
          subHeadingStyle: 'tg-mobile-body-small-bold',
          subHeading: this.$t('visit_reserved')
        };

      return slot.booking_set.length ? reserved[key] : notReserved[key];
    },
    showSlot(slot) {
      this.selectedTimeSlot = slot;
      this.slotModal = true;
    },
    closeSlotModal() {
      this.slotModal = false;
      this.selectedTimeSlot = null;
    },
    removeTimeSlot(slot) {
      return deletePropertyTimeSlot(this.propertyId, slot.id)
        .then(() => {
          this.$store.dispatch('snackbar/show', {
            type: 'success',
            messageBold:
              formatDateForLocale(slot.datetime_start) +
              ' (' +
              formatHHmm(slot.datetime_start) +
              ' - ' +
              formatHHmm(slot.datetime_end) +
              ')',
            message: this.$t('has_been_removed'),
            timeout: 3000
          });
          this.closeSlotModal();
          this.$emit('updateTimeSlots');
        })
        .catch(error => {
          this.$formulate.handle(error, 'propertyVisitSlotDelete');
        });
    },
    getICS(ics_url) {
      return process.env.VUE_APP_CALENDAR_API_URL + ics_url;
    }
  }
};
</script>
